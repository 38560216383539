import "./HomeStyles.css";

import { FiMenu, FiX } from "react-icons/fi";

import Footer from "./Footer";
import { Link } from "react-router-dom";
import React from "react";
import { useState } from "react";

const Eula = () => {
  const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

  return (
    <div className="flex flex-col min-h-screen bg-[#424530] font-suse">
      {/* Header */}
      <div>
        <div className="flex justify-between px-2 py-4 md:px-8 md:py-4">
          <div>
            <img
              alt="leadsify-logo"
              src="images/logo-white.png"
              className="w-16 h-auto sm:w-28 hover:cursor-pointer"
            />
          </div>

          {/* Hamburger menu button for small screens */}
          <div className="flex items-center md:hidden">
            <button
              onClick={toggleMenu}
              className="text-white focus:outline-none"
            >
              {isOpen ? <FiX size={24} /> : <FiMenu size={24} />}
            </button>
          </div>

          {/* Links - visible on large screens */}
          <div className="flex-row items-center hidden space-x-4 md:flex sm:space-x-6">
            <p className="text-sm font-semibold text-white sm:text-lg md:text-2xl hover:cursor-pointer">
              <Link to="/" className="text-white">
                Home
              </Link>
            </p>
            <p className="text-sm font-semibold text-white sm:text-lg md:text-2xl hover:cursor-pointer">
              <Link to="/contact" className="text-white">
                Contact Us
              </Link>
            </p>
            <p className="text-sm font-semibold text-white sm:text-lg md:text-2xl hover:cursor-pointer">
              <Link
                to="https://www.dev-portal.latsuccess.com/auth/login"
                className="text-white"
              >
                Log in
              </Link>
            </p>
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          className={`md:hidden flex flex-col items-center space-y-4 transition-all duration-500 ease-in-out overflow-hidden ${
            isOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
          }`}
        >
          <p className="text-sm font-semibold text-white hover:cursor-pointer">
            <Link to="/" onClick={toggleMenu}>
              Home
            </Link>
          </p>
          <p className="text-sm font-semibold text-white hover:cursor-pointer">
              <Link to="/privacy" onClick={toggleMenu}>
                Privacy
              </Link>
            </p>
            <p className="text-sm font-semibold text-white hover:cursor-pointer">
              <Link to="/terms" onClick={toggleMenu}>
              Terms & Conditions
              </Link>
            </p>
          <p className="text-sm font-semibold text-white hover:cursor-pointer">
            <Link to="/contact" onClick={toggleMenu}>
              Contact Us
            </Link>
          </p>
          <p className="text-sm font-semibold text-white hover:cursor-pointer">
            <Link
              to="https://www.dev-portal.latsuccess.com/auth/login"
              onClick={toggleMenu}
            >
              Log in
            </Link>
          </p>
        </div>
      </div>
 
      {/* Main Content */}
      <div className="flex flex-col px-6 py-8 space-y-8 md:px-16 lg:px-20 bg-[#fff8f2] text-[#424530]" >
        <section className="space-y-4">
          <h1 className="text-2xl font-bold md:text-4xl">
          Latsuccess End User License Agreement (EULA)
          </h1>
        </section>

        <section className="space-y-4">
          <h3 className="text-xl font-bold md:text-3xl">
            1. Terms and Conditions (EULA):
          </h3>
          <p className="text-lg md:text-xl">
          The link to your Terms and Conditions (End User License Agreement) must include the following:
          </p>
          <ul className="pl-4 space-y-2 list-disc list-inside">
            <li><b>Acceptance of Terms:</b> By using the app and subscribing to the service, you agree to the terms of this agreement. If you do not agree with any part of the agreement, do not use the app or subscribe to the service.</li>
            <li><b>User Rights and Responsibilities:</b> You are granted a non-exclusive, non-transferable license to access and use the app for personal use. You must not use the app for unlawful purposes, interfere with its functionality, or distribute it for commercial purposes without our permission.</li>
            <li><b>Payment Terms:</b> The subscription is priced at $12.99 CAD per month, and payments will be charged through Google Play / App Store. Subscription fees will be automatically renewed at the end of each billing cycle unless canceled.</li>
            <li><b>Cancellation and Refund Policy:</b> You may cancel your subscription anytime via the App Store/Google Play. Cancellations take effect at the end of the current billing cycle. Refunds are not offered, offered under specific circumstances, etc.</li>
            <li><b>Limitation of Liability:</b> The app is provided 'as is.' We do not guarantee uninterrupted service, and we are not liable for any damages arising from the use or inability to use the app.</li>
            <li><b>Termination Clause:</b> We may terminate or suspend your account if you violate the terms of this agreement. You will not be entitled to a refund if your account is terminated due to a violation.</li>
            <li><b>Changes to Terms:</b> We may update these terms from time to time. You will be notified of any significant changes. Continued use of the app after updates implies acceptance of the new terms.</li>
            <li><b>Governing Law:</b> This agreement is governed by the laws of the State, and disputes will be resolved in the courts.</li>
          </ul>
        </section>
      </div>
    
      {/* Footer */}
      <Footer/>
    </div>
  );
};

export default Eula;