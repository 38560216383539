import { useState } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { Link } from "react-router-dom"
const Header = () =>{
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    return(
        <div className="flex justify-between px-2 py-4 md:px-8 md:py-8">
        <div>
          <Link to={'/'}>
          <img
            alt="leadsify-logo"
            src="images/logo.png"
            className="h-auto w-28 sm:w-48 hover:cursor-pointer"
          />
          </Link>
        </div>

        {/* Hamburger menu button for small screens */}
        <div className="flex items-center md:hidden">
          <button
            onClick={toggleMenu}
            className="text-[#424530] focus:outline-none"
          >
            {isOpen ? <FiX size={24} /> : <FiMenu size={24} />}
          </button>
        </div>

        {/* Links - visible on large screens */}
        <div className="flex-row hidden space-x-4 md:flex sm:space-x-6">
            <p className="text-sm font-semibold text-white sm:text-lg md:text-xl hover:cursor-pointer">
            <Link to="/" className="text-[#424530]">
              Home
            </Link>
          </p>
          <p className="text-sm font-semibold text-white sm:text-lg md:text-xl hover:cursor-pointer">
            <Link to="/contact" className="text-[#424530]">
              Contact Us
            </Link>
          </p>
          <p className="text-sm font-semibold text-white sm:text-lg md:text-xl hover:cursor-pointer">
            <Link
              to="https://www.dev-portal.latsuccess.com/auth/login"
              className="text-[#424530]"
            >
              Log in
            </Link>
          </p>
        </div>
      </div>
    )
}

export default Header