const Footer=()=>{
    return(
        <div className="flex justify-between py-6 px-6 bg-[#424530] text-white">
            <div className="text-lg text-center">
                © {new Date().getFullYear()}. All Rights Reserved by <b> LATsuccess Inc.</b> 


                {/* <span className="mx-2 hover:cursor-pointer">Facebook</span>|
                <span className="mx-2 hover:cursor-pointer">Twitter</span>|
                <span className="mx-2 hover:cursor-pointer">LinkedIn</span>|
                <span className="mx-2 hover:cursor-pointer">Instagram</span> */}
            </div>

           <div className="flex ">
                <div className="mx-2 hover:cursor-pointer"><a href="/privacy">Privacy Policy</a></div> |
                <div className="mx-2 hover:cursor-pointer"><a href="/terms">Terms & Conditions</a></div>
           </div>
        </div>
    )
}

export default Footer